.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.messageContainer {
  font-size: 16px;
  font-weight: bold;
  color: #d9534f; /* Rojo para destacar */
  background-color: #f8d7da; /* Fondo claro para que resalte */
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 10px;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainMenu{
  width:100%;
  height: auto;
  margin:auto;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(131, 96, 195) 0%, rgb(46, 191, 145) 100%);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px;
}
/*
.tasarBtn{
  padding: 10px;
  width: 100%;
  margin-top: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(130, 194, 234);
  border: none;
  font-weight: bold;
}
*/
.buttonContainer {
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.tasarBtn {
  background-color: #FFD700!important;
  color: rgb(57, 36, 56);
  font-size: 18px;
  font-weight: bold;
  padding: 15px 20px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  transition: 0.3s ease-in-out;
  animation: pulse 1.5s infinite;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
  margin-bottom: 15px;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}


.tasarBtn:hover {
  transform: translateY(-3px); /* Lift effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.tasarBtn:active {
  transform: scale(0.97); /* Slight shrink on click */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15); /* Softer shadow on click */
}

select {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}
.infoMessage {
  font-size: 14px;
  color: #555;
  text-align: center;
  margin-bottom: 10px;
}

.instantMessage {
  font-weight: bold;
  color: #2EBF91; /* O el color que prefieras */
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  background: #e8f5e9;
  padding: 8px;
  border-radius: 5px;
}

.wpp{
  position:fixed;
  right:0;
  bottom:0;
  z-index: 3;
  padding: 20px;
  background-color: transparent;
  border-style: none;
  
}

.wpp_img{
  background-size: cover;
  width: 65px;
  height: 65px;
  cursor: pointer;
  
}

.login-admin{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: fit-content;
  width: fit-content;
  padding: 20px;
  margin: auto;
  text-align: center;
  border-radius: 20px;
  margin-top: 20px;
}

.input-login-admin{
  padding: 5px;
  margin-top: 5px;
}

.infoCard{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: fit-content;
  width: fit-content;
  margin: auto;
  border-radius: 20px;
  margin-top: 10px;
}

.tables{
  padding: 5px;
}