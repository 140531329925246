/* ModalCredits.css */
.modal-content {
  background-color: #f8f9fa; /* Light background color */
  border-radius: 10px; /* Rounded corners */
}

.modal-header {
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(131, 96, 195) 0%, rgb(46, 191, 145) 100%);
  color: white; /* White text color */
}

.modal-title {
  font-size: 1.25rem; /* Larger title font size */
}

.modal-body p {
  font-size: 1rem; /* Standard body font size */
  color: #495057; /* Darker text color */
  margin-bottom: 1rem; /* Space below the description */
}

.form-control {
  margin-bottom: 1rem; /* Space between form fields */
}

.form-group label {
  font-weight: bold; /* Bold labels for better readability */
}

.btn-primary {
  background-color: #007bff; /* Match button color with header */
  border: none; /* Remove border */
  width: 100%;
}

.btn-primary:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.btn-secondary {
  background-color: #6c757d; /* Gray color for secondary button */
  border: none; /* Remove border */
}

.btn-secondary:hover {
  background-color: #5a6268; /* Darker gray on hover */
}

/* Custom styles for full-width button */
.modal-footer {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  padding: 1rem; /* Add padding if needed */
}

.modal-footer .btn-primary {
  width: 100%; /* Make button full-width */
  max-width: 300px; /* Optional: Set a maximum width for the button */
}

/* Custom spinner styles */
.spinner-border {
  border-width: 2px; /* Adjust spinner size if needed */
}
